import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"
import { useQuery } from "react-query"

import debounce from "lodash/debounce"
import queryString from "query-string"
import styled from "styled-components/native"

import { IndexObject } from "@treefort/api-spec"
import { CONTENT_TYPES } from "@treefort/constants"
import { getSearchResultMetadata } from "@treefort/lib/algolia"
import { ResultAsync, Result } from "@treefort/lib/result"

import config from "../config"
import { useActiveProfileId } from "../hooks/use-active-profile-id"
import useAppManifest from "../hooks/use-app-manifest"
import useCollection from "../hooks/use-collection"
import { useIsFocused } from "../hooks/use-is-focused"
import { useNavigate } from "../hooks/use-navigate"
import { usePageArtworkMedia } from "../hooks/use-page-artwork-media"
import { useRouteParams } from "../hooks/use-route-params"
import { useSetting } from "../hooks/use-setting"
import analytics from "../lib/analytics"
import api from "../lib/api"
import { stringKeyLookup } from "../lib/i18n/string-key-lookup"
import settings from "../lib/settings"
import { getTextStyleString } from "../lib/text-style"
import {
  getPathFromCollection,
  getPathFromContent,
  getPathFromPageWithSlug,
  SEARCH_TAB,
} from "../navigation/routes"
import Box from "./box"
import Grid from "./grid"
import Icon from "./icon"
import { IconSize } from "./icon"
import IconButton from "./icon-button"
import Spacer from "./spacer"
import Text from "./text"
import TextInput from "./text-input"
import { SquareMediaThumbnail } from "./thumbnail"
import ThumbnailListItem from "./thumbnail-list-item"
import ThumbnailWithTitle from "./thumbnail-with-title"
import { useTokens } from "./tokens-provider"
import Touchable from "./touchable"

const MAX_SEARCH_RESULTS = 20
const MAX_PREVIOUS_SEARCH_RESULTS = 10
const LOADING_PLACEHOLER_COUNT = 4
const MIN_QUERY_LENGTH = 3
const DEBOUNCE_WAIT_MILLISECONDS = 500
const PREVIOUS_RESULTS_KEY = "searchPreviousResults"

const Context = React.createContext<
  | {
      query: string
      setQuery: (query: string) => void
      results: ResultAsync<SearchIndexObject[]>
    }
  | undefined
>(undefined)

const SEARCH_INDEX_OBJECT_TYPES = [
  ...CONTENT_TYPES,
  "page",
  "collection",
] as const

type SearchIndexObject = Extract<
  IndexObject,
  { objectType: (typeof SEARCH_INDEX_OBJECT_TYPES)[number] }
>

const StyledTextInput = styled(TextInput)`
  ${(props) => getTextStyleString("headingLarge", props.theme)};
  padding-left: ${(props) => props.theme.searchScreen.textInput.paddingLeft}px;
  flex: 1;
  color: ${(props) => props.theme.colors.text.primary};
  height: ${(props) => props.theme.searchScreen.textInput.height}px;
  /* Unset the default min-width applied by most browsers */
  min-width: 0;
  /* Hide the focus ring. A big a11y faux pas. The input is giant though, it's
  the only one on the page, the default focus ring looks bad, and RN web makes
  it very hard to change the styles. */
  ${Platform.OS === "web" ? "outline-width: 0" : ""};
  ${Platform.OS === "web" ? "outline-style: none" : ""};
`

const SearchBar = styled.View`
  flex-direction: row;
  align-items: center;
  border-bottom-width: ${(props) =>
    props.theme.searchScreen.textInput.underlineHeight}px;
  border-bottom-color: ${(props) => props.theme.colors.border.primary};
`

const SearchIcon = styled(Icon)`
  flex-shrink: 0;
`

const ClearTextInputButton = styled(IconButton)<{
  iconSize: IconSize
}>`
  flex-shrink: 0;
  margin-right: -${({ theme, iconSize }) => (theme.minTapTarget - theme.icon.size[iconSize]) / 2}px;
`

const PreviousResultsHeader = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PreviousResultItemContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const PreviousResultItemLinkContainer = styled.View`
  flex: 1;
`

const PreviousResultItemClearButton = styled(IconButton)<{
  iconSize: IconSize
}>`
  flex-shrink: 0;
  margin-right: -${({ theme, iconSize }) => (theme.minTapTarget - theme.icon.size[iconSize]) / 2}px;
`

const DesktopSearchResultItemContainer = styled.View`
  flex: 1;
`

function SearchResultItem({
  item,
  onPress: onPressProp,
}: {
  item?: SearchIndexObject
  onPress?: (item: SearchIndexObject) => void
}): JSX.Element {
  const { tokens } = useTokens()
  const pageArtworkMedia = usePageArtworkMedia(
    item?.objectType === "page" ? item.details.pageId : undefined,
  )
  const collectionQuery = useCollection(
    item?.objectType === "collection" ? item.details.collectionId : 0,
    { enabled: Boolean(item) && item?.objectType === "collection" },
  )

  const onPress = useCallback(() => {
    if (item && onPressProp) {
      onPressProp(item)
    }
  }, [onPressProp, item])

  const metadata = item
    ? getSearchResultMetadata({
        item,
        artworkInputs: {
          collection: collectionQuery.data,
          pageArtworkMedia,
        },
      })
    : undefined

  if (tokens.searchScreen.mode === "desktop") {
    return (
      <DesktopSearchResultItemContainer>
        <Touchable onPress={onPress}>
          <SquareMediaThumbnail media={metadata?.artworkMedia} />
          <Spacer size="tiny" />
          <ThumbnailWithTitle
            titleNumberOfLines={1}
            data={
              item
                ? {
                    title: item.title,
                    subtitle: metadata?.subtitle,
                    displayTypeStringKey:
                      stringKeyLookup.searchObjectType[item.objectType],
                  }
                : undefined
            }
          />
        </Touchable>
      </DesktopSearchResultItemContainer>
    )
  } else if (item) {
    return (
      <ThumbnailListItem
        role="link"
        aria-label={item.title}
        data={{
          onPress,
          artworkMedia: metadata?.artworkMedia,
          title: item.title,
          subtitle: metadata?.subtitle,
          displayTypeStringKey:
            stringKeyLookup.searchObjectType[item.objectType],
        }}
      />
    )
  } else {
    return <ThumbnailListItem />
  }
}

export function SearchProvider({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  const [params, setParams] = useRouteParams()
  const debouncedQuery = params.q
  const [query, setQuery] = useState<string>(debouncedQuery || "")
  const queryIsValid = query.trim().length >= MIN_QUERY_LENGTH

  const setDebouncedQuery = useCallback(
    (query: string | undefined) => setParams({ q: query }),
    [setParams],
  )

  const debouncedSearch = useMemo(
    () => debounce(setDebouncedQuery, DEBOUNCE_WAIT_MILLISECONDS),
    [setDebouncedQuery],
  )

  const search = useQuery(
    ["search", debouncedQuery],
    () => {
      return debouncedQuery
        ? api
            .get<SearchIndexObject[]>(
              `/search?${queryString.stringify({
                q: debouncedQuery,
                platform: Platform.OS,
                appId: config.APP_ID,
                maxHits: MAX_SEARCH_RESULTS,
                objectType: SEARCH_INDEX_OBJECT_TYPES,
              })}`,
            )
            .then((res) => {
              const results = res.data
              analytics.logSearch({
                query: debouncedQuery,
                resultsCount: results.length,
              })
              return results
            })
        : []
    },
    { enabled: debouncedQuery !== undefined },
  )

  useEffect(() => {
    if (query !== debouncedQuery) {
      if (queryIsValid) {
        debouncedSearch(query)
      } else {
        debouncedSearch.cancel()
        setDebouncedQuery(undefined)
      }
    }
  }, [query, queryIsValid, debouncedSearch, debouncedQuery, setDebouncedQuery])

  return (
    <Context.Provider
      value={{
        query,
        setQuery,
        results:
          queryIsValid && search.data && !search.isFetching
            ? Result.success(search.data)
            : queryIsValid && search.isError
              ? Result.error(search.error)
              : queryIsValid
                ? Result.loading()
                : Result.idle(),
      }}
    >
      {children}
    </Context.Provider>
  )
}

export function SearchInput(): JSX.Element | null {
  const manifest = useAppManifest()
  const search = useContext(Context)
  const { tokens } = useTokens()
  const searchInputRef = useRef<
    TextInput & { clear: () => void; focus: () => void }
  >(null)
  const isFocused = useIsFocused()
  const { t } = useTranslation()

  if (!search) {
    throw new Error(
      "No context found - make sure SearchInput is rendered inside SearchProvider.",
    )
  }

  useEffect(() => {
    if (isFocused && searchInputRef.current && !search.query) {
      searchInputRef.current.focus()
    }
  }, [isFocused, search.query])

  return (
    <Box paddingHorizontal="pagePaddingHorizontal">
      <SearchBar>
        <SearchIcon source={manifest.icons.search} size="large" />
        <StyledTextInput
          ref={searchInputRef}
          maxLength={255}
          numberOfLines={1}
          defaultValue={search.query}
          onChangeText={search.setQuery}
          multiline={false}
          placeholder={t("Search Everything")}
          placeholderTextColor={tokens.colors.text.secondary}
        />
        {search.query.length > 0 ? (
          <ClearTextInputButton
            onPress={() => {
              search?.setQuery("")
              searchInputRef.current?.clear()
            }}
            source={manifest.icons.close}
            iconSize="medium"
            label={t("Clear search")}
          />
        ) : null}
      </SearchBar>
    </Box>
  )
}

export function SearchResults(): JSX.Element | null {
  const search = useContext(Context)
  const manifest = useAppManifest()

  if (!search) {
    throw new Error(
      "No context found - make sure SearchResults is rendered inside SearchProvider.",
    )
  }

  const navigate = useNavigate()
  const { tokens } = useTokens()
  const profileId = useActiveProfileId()
  const [previousResults, setPreviousResults] = useSetting<SearchIndexObject[]>(
    {
      key: PREVIOUS_RESULTS_KEY,
      profileId,
      strategy: "local",
      settings,
      defaultValue: [],
    },
  )
  const { t } = useTranslation()

  function clearAllPreviousResults() {
    setPreviousResults([])
  }

  function clearPreviousResult(objectId: string) {
    setPreviousResults(
      previousResults.filter((result) => result.objectID !== objectId),
    )
  }

  function navigateToItem(item: SearchIndexObject) {
    const path = getSearchResultPath(item)
    if (!path) {
      return
    }

    navigate(
      path,
      // On the web we can rely on the browser's history to correctly handle back
      // actions. On native we've got to provide react-navigation a hint re: where
      // the back button should point because we're jumping across navigators. We
      // also disable navigation animation on native to avoid an unnatural
      // animations if a page already exists in the search tab navigator.
      Platform.OS === "web"
        ? undefined
        : {
            goBackTo: "search",
            q: search?.query,
            navAnimation: "disabled",
          },
      "push",
    )
  }

  async function onPressSearchResult(item: SearchIndexObject) {
    const id = getSearchResultId(item)
    if (!id) {
      return
    }

    // Save the result in the "Previous Searches" store
    setPreviousResults(
      [
        item,
        ...previousResults.filter(
          (previousItem) => previousItem.objectID !== item.objectID,
        ),
      ].slice(0, MAX_PREVIOUS_SEARCH_RESULTS),
    )

    analytics.logSearchResultChosen({
      resultId: id.toString(),
      resultType: item.objectType,
    })

    navigateToItem(item)
  }

  // No results for the query
  if (search.results.isSuccess && !search.results.data.length) {
    return (
      <Box
        paddingTop="xlarge"
        paddingBottom="large"
        paddingHorizontal="pagePaddingHorizontal"
      >
        <Text color="secondary" textStyle="body">
          {t("No Results")}
        </Text>
      </Box>
    )
  }
  // Search results or loading state
  else if (search.results.isSuccess || search.results.isLoading) {
    const items: JSX.Element[] = (
      search.results.isSuccess
        ? search.results.data
        : search.results.isLoading
          ? new Array(LOADING_PLACEHOLER_COUNT).fill(undefined)
          : []
    ).map((item: SearchIndexObject | undefined, i) => (
      <SearchResultItem
        item={item}
        key={`${i}-${
          item ? item.title + "-" + item.objectID : "loading-placeholder"
        }`}
        onPress={onPressSearchResult}
      />
    ))

    return tokens.searchScreen.mode === "desktop" ? (
      <>
        <Spacer size="jumbo" />
        <Grid
          items={items}
          itemsPerRow={tokens.searchScreen.grid.itemsPerRow}
          paddingHorizontal="pagePaddingHorizontal"
        />
        <Spacer size="large" />
      </>
    ) : (
      <Box paddingTop="small" paddingBottom="small">
        {items}
      </Box>
    )
  }
  // Previous results (search results that user previously tapped)
  else if (previousResults.length) {
    return (
      <Box
        paddingTop="xlarge"
        paddingBottom="large"
        paddingHorizontal="pagePaddingHorizontal"
      >
        <PreviousResultsHeader>
          <Text color="secondary" textStyle="body">
            {t("Previous Searches")}
          </Text>
          <Touchable
            onPress={clearAllPreviousResults}
            hitSlop={{ top: 8, bottom: 8, left: 0, right: 0 }}
            feedback="opacity"
          >
            <Text color="secondary" textStyle="body">
              {t("Clear All")}
            </Text>
          </Touchable>
        </PreviousResultsHeader>
        <Spacer size="medium" />
        {previousResults.map((item) => (
          <PreviousResultItemContainer
            key={`previous-${item.objectID}`}
            role="link"
            aria-label={item.title}
          >
            <PreviousResultItemLinkContainer>
              <Touchable
                onPress={() => navigateToItem(item)}
                feedback="opacity"
              >
                <Text
                  color="secondary"
                  textStyle="headingSmall"
                  numberOfLines={1}
                >
                  {item.title}
                </Text>
              </Touchable>
            </PreviousResultItemLinkContainer>
            <Spacer size="medium" horizontal />
            <PreviousResultItemClearButton
              onPress={() => clearPreviousResult(item.objectID)}
              color="secondary"
              iconSize="small"
              source={manifest.icons.close}
              label={t("Clear search")}
            />
          </PreviousResultItemContainer>
        ))}
      </Box>
    )
  } else {
    return null
  }
}

/**
 * Returns the path that a search result should link to.
 */
function getSearchResultPath(item: SearchIndexObject) {
  switch (item.objectType) {
    case "book":
    case "ebook":
    case "podcast":
    case "video":
    case "videoSeries":
    case "webEmbed":
    case "album":
      return getPathFromContent(
        item.details.contentId,
        item.objectType,
        SEARCH_TAB,
      )

    case "page":
      return getPathFromPageWithSlug(
        item.details.pageId,
        SEARCH_TAB,
        item.details.slug,
      )

    case "collection":
      return getPathFromCollection(item.details.collectionId, SEARCH_TAB)

    default:
      item satisfies never
      return undefined
  }
}

/**
 * Returns the ID of the resource that a search result links to
 */
function getSearchResultId(item: SearchIndexObject) {
  switch (item.objectType) {
    case "book":
    case "ebook":
    case "podcast":
    case "video":
    case "videoSeries":
    case "webEmbed":
    case "album":
      return item.details.contentId

    case "page":
      return item.details.pageId

    case "collection":
      return item.details.collectionId

    default:
      item satisfies never
      return undefined
  }
}
