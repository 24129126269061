export const stringKeyLookup = {
  playAction: {
    listen: "Listen",
    read: "Read",
    watch: "Watch",
    view: "View",
  },
  subscribeAction: {
    listen: "Subscribe to Listen",
    read: "Subscribe to Read",
    watch: "Subscribe to Watch",
    view: "Subscribe to view",
  },
  upgradeAction: {
    listen: "Upgrade to Listen",
    read: "Upgrade to Read",
    watch: "Upgrade to Watch",
    view: "Upgrade to view",
  },
  createAccountAction: {
    listen: "Create a FREE account to listen",
    read: "Create a FREE account to read",
    watch: "Create a FREE account to watch",
    view: "Create a FREE account to view",
  },
  libraryTabTitles: {
    inProgress: "In Progress",
    notStarted: "Not Started",
    finished: "Finished",
    downloaded: "Downloaded",
    purchased: "Purchased",
  },
  libraryTabEmptyMessages: {
    inProgress: "When you start playing content it will show up here.",
    notStarted:
      "When you add new content to your library it will show up here.",
    finished: "When you finish content it will show up here.",
    downloaded: "When you download content it will show up here.",
  },
  contentType: {
    book: "Audiobook",
    ebook: "eBook",
    video: "Video",
    podcast: "Podcast",
    videoSeries: "Series",
    webEmbed: undefined,
    publisher: "Publisher",
    album: "Album",
  },
  consumableContentType: {
    podcastEpisode: "Podcast",
  },
  searchObjectType: {
    podcast: "Podcast",
    book: "Audiobook",
    ebook: "eBook",
    video: "Video",
    videoSeries: "Series",
    collection: "Collection",
    page: "Page",
    // No display type for web embeds since they could be anything
    webEmbed: undefined,
    publisher: "Publisher",
    album: "Album",
  },
} as const
