import React, { useCallback, useMemo, useState } from "react"
import { LayoutChangeEvent, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import { simpleHash } from "@treefort/lib/simple-hash"

import { getAbsoluteLineHeight } from "../lib/text-style"
import ListView from "./list-view"
import Modal, { MODAL_HEADER_HEIGHT_PX } from "./modal"
import Row from "./row"
import Text from "./text"
import { useTokens } from "./tokens-provider"
import Touchable from "./touchable"

export type Option<T> = { label: string; value: T; depth?: number }

type Numbering = { index: number; optionCount: number }

const OPTION_TEXT_STYLE = "headingSmall"

const OPTION_PADDING_VERTICAL = 12

// Unlocks a scroll view during body scroll locking on the web
const WEB_SCROLL_UNLOCK = { webScrollUnlock: true }

const OptionContainer = styled(Row)<{
  depth: number
  selected: boolean | undefined
  numbering: Numbering | undefined
}>`
  margin-left: ${(props) =>
    props.depth * 24 +
    (!props.selected && !props.numbering
      ? props.theme.trackPicker.currentDot.size * 2
      : 0)}px;
`

const SelectedDot = styled.View`
  width: ${(props) => props.theme.trackPicker.currentDot.size}px;
  height: ${(props) => props.theme.trackPicker.currentDot.size}px;
  border-radius: ${(props) => props.theme.trackPicker.currentDot.size}px;
  background-color: ${(props) => props.theme.trackPicker.currentDot.color};
`

const MeasureView = styled.View`
  position: absolute;
  top: ${MODAL_HEADER_HEIGHT_PX}px;
  bottom: 0;
  left: 0;
  right: 0;
`

/**
 * This creates a consistent-width column for option numbers so that option
 * labels have consistent indentation regardless of whether they are numbered
 * with 1, 11, 111, or 1111. Each digit is given 12px which is very
 * un-scientific, but works and includes wiggle room for font variations.
 */
const OptionNumber = styled.View<{ numbering: Numbering | undefined }>`
  width: ${(props) =>
    (props.numbering?.optionCount || "").toString().length * 12}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export function SelectOption<T>({
  option,
  numbering,
  selected,
  onPress,
  ...rowProps
}: {
  option: Option<T>
  numbering?: Numbering
  selected?: boolean
  onPress?: (value: T) => void
} & Omit<Parameters<typeof Row>[0], "children" | "gap">) {
  const label = (
    <Text textStyle={OPTION_TEXT_STYLE} numberOfLines={1}>
      {option.label}
    </Text>
  )
  const item = (
    <OptionContainer
      gap="xsmall"
      depth={option.depth || 0}
      selected={selected}
      numbering={numbering}
      {...rowProps}
    >
      {numbering ? (
        <OptionNumber numbering={numbering}>
          {selected ? (
            <SelectedDot />
          ) : (
            <Text textStyle="body" color="secondary">
              {numbering.index + 1}
            </Text>
          )}
        </OptionNumber>
      ) : selected ? (
        <SelectedDot />
      ) : null}
      {label}
    </OptionContainer>
  )
  return onPress ? (
    <Touchable
      onPress={() => onPress(option.value)}
      feedback="ripple-or-highlight"
      role="button"
      aria-label={option.label}
    >
      {item}
    </Touchable>
  ) : (
    item
  )
}

export default function Select<T>({
  open,
  options,
  value,
  label,
  numbered,
  onChange,
  onClose,
}: {
  open: boolean
  options: Option<T>[]
  value?: T
  label?: string
  numbered?: boolean
  onChange?: (value: T) => void
  onClose: () => void
}): JSX.Element {
  const { tokens } = useTokens()
  const [viewSize, setViewSize] = useState<{ width: number; height: number }>()
  const safeAreaInsets = useSafeAreaInsets()
  const handleLayout = useCallback(
    (event: LayoutChangeEvent) =>
      setViewSize({
        width: event.nativeEvent.layout.width,
        height: event.nativeEvent.layout.height,
      }),
    [],
  )
  const optionHeight = useMemo(
    () =>
      getAbsoluteLineHeight(OPTION_TEXT_STYLE, tokens) +
      OPTION_PADDING_VERTICAL * 2,
    [tokens],
  )
  return (
    <Modal
      title={label}
      open={open}
      onPressCloseButton={onClose}
      onPressOutside={onClose}
      type="sheet"
      backgroundColor="tertiary"
      portalHost="foreground"
    >
      {(modalType) => (
        <>
          <MeasureView onLayout={handleLayout} />
          {viewSize ? (
            <ListView
              items={options}
              viewSize={viewSize}
              style={[
                viewSize,
                { position: "absolute", top: MODAL_HEADER_HEIGHT_PX, left: 0 },
              ]}
              getItemKey={(option) =>
                typeof option.value === "string"
                  ? option.value
                  : typeof option.value === "number"
                    ? option.value.toString()
                    : simpleHash(option)
              }
              getItemSize={() => optionHeight}
              initialRenderIndex={options.findIndex((o) => o.value === value)}
              scrollEventThrottle={8}
              scrollViewDataSet={WEB_SCROLL_UNLOCK}
              paddingEnd={
                modalType === "sheet" ? safeAreaInsets.bottom : undefined
              }
              renderItem={(option, index) => (
                <SelectOption
                  option={option}
                  selected={option.value === value}
                  height={optionHeight}
                  onPress={onChange}
                  numbering={
                    numbered
                      ? { index, optionCount: options.length }
                      : undefined
                  }
                  paddingHorizontal="medium"
                />
              )}
            />
          ) : null}
          <View
            style={{
              // This View is used to reserve the space that would normally be
              // taken up by the tracks in the absolutely positioned ListView.
              // This ensures that the modal expands vertically as necessary to
              // accomodate larger numbers of tracks.
              height: options.length * optionHeight,
            }}
            pointerEvents="none"
          />
        </>
      )}
    </Modal>
  )
}
