import React from "react"
import { useTranslation } from "react-i18next"

import { Result } from "@treefort/lib/result"

import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"
import useAppManifest from "../../hooks/use-app-manifest"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import useContent from "../../hooks/use-content"
import { useRoute } from "../../hooks/use-route"
import { i18nKey } from "../../types/i18next"
import { ModuleLayout } from "../layouts/module"
import { AlbumListViewModule } from "../layouts/module/list-view-modules/album"
import { CardListViewModule } from "../layouts/module/list-view-modules/card"
import { SliderListViewModule } from "../layouts/module/list-view-modules/slider"

export function ContentAlbumScreen(): JSX.Element {
  const manifest = useAppManifest()
  const { params } = useRoute<"contentId">()
  const { t } = useTranslation()

  if (!params.contentId) {
    throw new Error(`Visited the album screen without a content id!`)
  }

  const contentId = parseInt(params.contentId)

  const album = useContent(contentId, "album")

  const listViewModules = Result.mapSuccess(album, (album) =>
    Result.success([
      new AlbumListViewModule({
        id: 0,
        type: "album",
        config: { contentId },
        conditions: {},
      }),
      ...(album.links.length
        ? [
            new CardListViewModule({
              id: -1,
              type: "card",
              config: {
                items: album.links,
              },
              conditions: {},
            }),
          ]
        : []),
      ...(manifest.features.contentPageRecommendations
        ? [
            new SliderListViewModule(
              {
                id: -2,
                type: "slider",
                config: {
                  title: t(
                    manifest.strings.contentPageRecommendationsTitle as i18nKey,
                  ),
                  itemShape: "square",
                  size: "medium",
                  showItemTitles: true,
                  items: {
                    type: "recombee",
                    recommendation: {
                      type: "itemsToItem",
                      contentId,
                      personalized:
                        manifest.features.contentPageRecommendations
                          .personalized,
                      scenario:
                        manifest.features.contentPageRecommendations.scenario,
                    },
                  },
                },
                conditions: {},
              },
              { key: `content-page-recommendations-${contentId}` },
            ),
          ]
        : []),
    ]),
  )

  const asyncViewProps = useAsyncViewPropsForQueries(album)
  const key = `album-page-${contentId}`

  return (
    <AsyncViewOfflineProvider
      key={key}
      {
        // If we failed to load what we need at this level then provide that
        // information to the async view so that an appropriate error message
        // can be rendered. Otherwise delegate the async view props to our
        // children (by omitting them here).
        ...(asyncViewProps.state === "error" ? asyncViewProps : null)
      }
    >
      <ModuleLayout pageKey={key} listViewModules={listViewModules} />
    </AsyncViewOfflineProvider>
  )
}
