import { useState, useEffect, useRef } from "react"

import player, {
  Progress,
  PlaybackState,
  Track,
  Event,
} from "../lib/audio-player"
import { SkipMode } from "../lib/av/types"
import { useEventEmitterValue } from "./use-event-emitter-value"

export const usePlaybackState = (): PlaybackState => {
  const debounceTimeout = useRef<number>()
  const [playbackState, setPlaybackState] = useState(player.getPlaybackState())
  useEffect(() => {
    const removeListener = player.on(
      Event.PlaybackState,
      (nextPlaybackState) => {
        // Debounce all events *except* for "Loading" and "Buffering" for 250ms.
        // This prevents the play and pause buttons from flickering in the UI
        // every time there's a little hiccup in the audio player.
        window.clearTimeout(debounceTimeout.current)
        if (
          nextPlaybackState === PlaybackState.Loading ||
          nextPlaybackState === PlaybackState.Buffering
        ) {
          setPlaybackState(nextPlaybackState)
        } else {
          debounceTimeout.current = window.setTimeout(
            () => setPlaybackState(nextPlaybackState),
            250,
          )
        }
      },
    )
    return () => {
      window.clearTimeout(debounceTimeout.current)
      removeListener()
    }
  }, [])
  return playbackState
}

export const useTrack = (): Track | null =>
  useEventEmitterValue(player, Event.Track, player.getTrack)

export const useTrackIndex = (): number | undefined =>
  useEventEmitterValue(player, Event.TrackIndex, player.getIndex)

export const useTracks = (): Track[] =>
  useEventEmitterValue(player, Event.Tracks, player.getTracks)

export const usePlaybackRate = (): number =>
  useEventEmitterValue(player, Event.PlaybackRate, player.getPlaybackRate)

export const useSkipMode = (): SkipMode =>
  useEventEmitterValue(player, Event.SkipMode, player.getSkipMode)

export const useProgress = (): Progress =>
  useEventEmitterValue(player, Event.Progress, player.getProgress)

export const useSeekable = (): boolean =>
  useEventEmitterValue(player, Event.Seekable, player.getSeekable)
