import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import icons from "@treefort/tokens/app/icons"

import { useTracks, usePlaybackRate, useSkipMode } from "../hooks/audio"
import { useBooleanState } from "../hooks/use-boolean-state"
import audioPlayer, {
  SKIP_BACKWARD_INTERVAL,
  SKIP_FORWARD_INTERVAL,
} from "../lib/audio-player"
import { isConsumableContentTrack } from "../lib/consumable-content"
import AudioPlayerPlayButton from "./audio-player-play-button"
import RatePicker, { playbackRateToString } from "./audio-player-rate-picker"
import IconButton from "./icon-button"
import Select from "./select"
import Text from "./text"
import Touchable from "./touchable"

const RATE_BUTTON_HIT_SLOP = { top: 8, right: 8, bottom: 8, left: 8 }

const Container = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const OuterControlContainer = styled.View`
  width: ${(props) => props.theme.minTapTarget}px;
  align-items: center;
  justify-content: center;
`

export default function AudioPlayerControls({
  compact,
}: {
  compact?: boolean
}): JSX.Element {
  const [trackPickerOpen, openTrackPicker, closeTrackPicker] =
    useBooleanState(false)
  const [ratePickerOpen, openRatePicker, closeRatePicker] =
    useBooleanState(false)
  const tracks = useTracks()
  const playbackRate = usePlaybackRate()
  const skipMode = useSkipMode()
  const skipPositionBackward = useCallback(
    () => audioPlayer.skipPosition(-SKIP_BACKWARD_INTERVAL),
    [],
  )
  const skipPositionForward = useCallback(
    () => audioPlayer.skipPosition(SKIP_FORWARD_INTERVAL),
    [],
  )

  const onPickTrack = useCallback(
    async (index: number) => {
      closeTrackPicker()
      await audioPlayer.skipToTrack(index, { autoPlay: true })
    },
    [closeTrackPicker],
  )

  const isAlbum = tracks.every(
    (track) =>
      isConsumableContentTrack(track) &&
      track.extra.consumableContent.type === "album",
  )

  const includeTrackPicker = tracks.length > 1
  const includeRatePicker = !isAlbum

  const { t } = useTranslation()

  return (
    <Container>
      <OuterControlContainer>
        {includeRatePicker ? (
          <Touchable
            onPress={openRatePicker}
            feedback="opacity"
            hitSlop={RATE_BUTTON_HIT_SLOP}
          >
            <Text textStyle="headingSmall">
              {playbackRateToString(playbackRate)}
            </Text>
          </Touchable>
        ) : null}
      </OuterControlContainer>
      {skipMode === "track" ? (
        <IconButton
          onPress={audioPlayer.skipBackward}
          iconSize={compact ? "large" : "xlarge"}
          source={icons.skipBackward}
          label={t("Previous")}
        />
      ) : (
        <IconButton
          onPress={skipPositionBackward}
          iconSize={compact ? "large" : "xlarge"}
          source={
            SKIP_BACKWARD_INTERVAL === 10000 ? icons.back10 : icons.back30
          }
          label={t("Skip backward {{number}} seconds", {
            number: SKIP_BACKWARD_INTERVAL / 1000,
          })}
        />
      )}
      <AudioPlayerPlayButton iconSize={compact ? "large" : "jumbo"} />
      {skipMode === "track" ? (
        <IconButton
          onPress={audioPlayer.skipForward}
          iconSize={compact ? "large" : "xlarge"}
          source={icons.skipForward}
          label={t("Next")}
        />
      ) : (
        <IconButton
          onPress={skipPositionForward}
          iconSize={compact ? "large" : "xlarge"}
          source={
            SKIP_FORWARD_INTERVAL === 10000 ? icons.forward10 : icons.forward30
          }
          label={t("Skip forward {{number}} seconds", {
            number: SKIP_FORWARD_INTERVAL / 1000,
          })}
        />
      )}

      <OuterControlContainer>
        {includeTrackPicker ? (
          <IconButton
            onPress={openTrackPicker}
            source={icons.menuStaggered}
            iconSize="medium"
            label={t("Open track picker")}
          />
        ) : null}
      </OuterControlContainer>
      {includeTrackPicker ? (
        <Select
          label={tracks[0]?.album}
          open={trackPickerOpen}
          onClose={closeTrackPicker}
          value={audioPlayer.getIndex()}
          onChange={onPickTrack}
          numbered={isAlbum}
          options={tracks.map((track, index) => ({
            label: track.title,
            value: index,
          }))}
        />
      ) : null}
      {includeRatePicker ? (
        <RatePicker open={ratePickerOpen} onRequestClose={closeRatePicker} />
      ) : null}
    </Container>
  )
}
