import React, { useMemo, useState, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import styled from "styled-components/native"

import { AlbumResponse, AlbumTrackResponse } from "@treefort/api-spec"
import { joinContributorNames } from "@treefort/lib/contributor"
import icons from "@treefort/tokens/app/icons"

import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import useAppManifest from "../../hooks/use-app-manifest"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import { useBooleanState } from "../../hooks/use-boolean-state"
import useContent from "../../hooks/use-content"
import { useOpenCheckoutPage } from "../../hooks/use-open-checkout-page"
import { useRouteParams } from "../../hooks/use-route-params"
import audioPlayer, { Event } from "../../lib/audio-player"
import {
  canDownloadConsumableContent,
  getConsumableContentFromAlbumResponse,
  getTracksFromConsumableContent,
  isConsumableContentTrack,
} from "../../lib/consumable-content"
import { playContentAudio } from "../../lib/content-audio"
import { formatDate } from "../../lib/date"
import AddToLibraryButton, { canAddToLibrary } from "../add-to-library-button"
import { AsyncButton } from "../async-button"
import { useAsyncViewProps } from "../async-view"
import Box, { BoxPadding } from "../box"
import Column from "../column"
import DownloadButton from "../download-button"
import { Heading } from "../heading"
import IconButton from "../icon-button"
import LockedContentButton from "../locked-content-button"
import { MetadataSpacer, MetadataText } from "../metadata"
import ModuleArtworkLayout from "../module-artwork-layout"
import ProgressForConsumableContent from "../progress-for-consumable-content"
import Row from "../row"
import { SelectOption } from "../select"
import Text from "../text"
import { TextToggleModal } from "../text-toggle"

const { buttonFlexContainerStyle } = StyleSheet.create({
  buttonFlexContainerStyle: { flex: 1 },
})

const PlayButton = styled(AsyncButton)`
  flex: ${(props) =>
    props.theme.audiobookModule.playButtonFlex ? "1" : "none"};
`

const LockedPlayButton = styled(LockedContentButton)`
  flex: ${(props) =>
    props.theme.audiobookModule.playButtonFlex ? "1" : "none"};
`

/**
 * Returns the index of the current track _if_ the provided album is loaded into
 * the audio player.
 */
function getCurrentTrackIndex(album: AlbumResponse) {
  const index = audioPlayer.getIndex()
  const track = audioPlayer.getTrack(index)
  return isConsumableContentTrack(track) &&
    track.extra.consumableContent.content.id === album.id
    ? index ?? undefined
    : undefined
}

export function AlbumModule({
  contentId,
  maxWidth,
  paddingTop = "medium",
}: {
  contentId: number
  maxWidth?: number
  paddingTop?: BoxPadding
}): JSX.Element {
  const { t, i18n } = useTranslation()
  const profileId = useActiveProfileId()
  const [artworkReady, setArtworkReady] = useState(false)
  const album = useContent(contentId, "album")
  const firstTrack = album.data?.details.tracks[0]
  const consumableContent = album.isSuccess
    ? getConsumableContentFromAlbumResponse(album.data)
    : undefined
  const tracks = useMemo(
    () => getTracksFromConsumableContent({ consumableContent, profileId }),
    [consumableContent, profileId],
  )
  const trackCount = tracks.length
  const manifest = useAppManifest()
  const showDescriptionButton = album.data && Boolean(album.data.description)
  const showDownloadButton =
    trackCount &&
    consumableContent &&
    canDownloadConsumableContent(consumableContent)
  const showAddToLibraryButton = canAddToLibrary({
    manifest,
    consumableContent,
  })

  const openCheckoutPage = useOpenCheckoutPage({
    availability: firstTrack?.audioMedia,
    contentId,
  })

  const playAlbum = useCallback(
    (trackIndex?: number) => {
      if (consumableContent) {
        return playContentAudio({ consumableContent, profileId, trackIndex })
      }
    },
    [consumableContent, profileId],
  )

  // If a track is specified in route params then autoplay that track
  const [params] = useRouteParams()
  const autoplayTrackIndex =
    params.track && !isNaN(parseInt(params.track))
      ? parseInt(params.track) - 1
      : null
  useEffect(() => {
    if (autoplayTrackIndex) {
      playAlbum(autoplayTrackIndex)
    }
  }, [playAlbum, autoplayTrackIndex])

  useAsyncViewProps(
    useAsyncViewPropsForQueries([album], {
      forceLoading: album.data?.artworkMedia ? !artworkReady : false,
    }),
  )

  const artist = joinContributorNames(album.data?.contributors, "artist")
  const publisher = joinContributorNames(album.data?.contributors, "publisher")

  return (
    <ModuleArtworkLayout
      paddingTop={paddingTop}
      artwork={album.data?.artworkMedia?.original.url}
      onArtworkReady={setArtworkReady}
      maxWidth={maxWidth}
      fullBleedContent
    >
      {({ layout }) => (
        <>
          <Box
            paddingHorizontal={
              layout === "portrait" ? "pagePaddingHorizontal" : "none"
            }
          >
            <Heading
              level={2}
              textStyle="headingLarge"
              color="primary"
              maxWidth="title"
            >
              {album.data?.title || ""}
            </Heading>
            <Row paddingTop="small">
              {artist ? <MetadataText>{artist}</MetadataText> : null}
              {artist && album.data?.publishedAt ? <MetadataSpacer /> : null}
              {album.data?.publishedAt ? (
                <MetadataText>
                  {formatDate(new Date(album.data.publishedAt), {
                    strategy: "fullDate",
                    i18n,
                  })}
                </MetadataText>
              ) : null}
              <ProgressForConsumableContent
                consumableContent={consumableContent}
                includeDurationLabel
                childrenBefore={
                  album.data?.publishedAt ? <MetadataSpacer /> : undefined
                }
              />
            </Row>
            {publisher ? <MetadataText>{publisher}</MetadataText> : null}
            <Row gap="large" paddingTop="medium">
              {firstTrack?.audioMedia?.status === "notAvailable" ? (
                <LockedPlayButton
                  openCheckoutPage={openCheckoutPage}
                  availability={firstTrack.audioMedia}
                  action="listen"
                  containerStyle={buttonFlexContainerStyle}
                />
              ) : (
                <PlayButton
                  icon={icons.headphones}
                  type="primary"
                  onPress={playAlbum}
                  disabled={!album.isSuccess}
                  containerStyle={buttonFlexContainerStyle}
                >
                  {t("Listen")}
                </PlayButton>
              )}
              <Row gap="small">
                {showDescriptionButton ? (
                  <DescriptionButton album={album.data} />
                ) : null}
                {showDownloadButton ? (
                  <DownloadButton consumableContent={consumableContent} />
                ) : null}
                {showAddToLibraryButton ? (
                  <AddToLibraryButton consumableContent={consumableContent} />
                ) : null}
              </Row>
            </Row>
          </Box>
          {album.data && consumableContent ? (
            <AlbumTracks
              album={album.data}
              albumLayout={layout}
              onPressTrack={playAlbum}
            />
          ) : null}
        </>
      )}
    </ModuleArtworkLayout>
  )
}

function AlbumTracks({
  album,
  albumLayout,
  onPressTrack,
}: {
  album: AlbumResponse
  albumLayout: "portrait" | "landscape"
  onPressTrack: (index: number) => unknown
}) {
  // Listen to the currently playing track so that we can highlight it in the
  // track list.
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    getCurrentTrackIndex(album),
  )

  useEffect(
    () =>
      audioPlayer.on(Event.TrackIndex, () =>
        setCurrentIndex(getCurrentTrackIndex(album)),
      ),
    [album],
  )

  return (
    <Column paddingTop="medium" alignItems="stretch">
      {album.details.tracks.map((track, index) => (
        <AlbumTrack
          key={index}
          track={track}
          trackCount={album.details.tracks.length}
          index={index}
          selected={currentIndex === index}
          onPress={onPressTrack}
          albumLayout={albumLayout}
        />
      ))}
    </Column>
  )
}
function AlbumTrack({
  track,
  index,
  trackCount,
  selected,
  onPress,
  albumLayout,
}: {
  track: AlbumTrackResponse
  index: number
  trackCount: number
  selected: boolean
  onPress: (index: number) => unknown
  albumLayout: "portrait" | "landscape"
}) {
  return (
    <SelectOption
      option={{ value: index, label: track.title }}
      selected={selected}
      onPress={onPress}
      numbering={{ index, optionCount: trackCount }}
      paddingVertical="small"
      paddingHorizontal={
        albumLayout === "portrait" ? "pagePaddingHorizontal" : "none"
      }
    />
  )
}

function DescriptionButton({ album }: { album: AlbumResponse }) {
  const { t } = useTranslation()
  const [modalOpen, openModal, closeModal] = useBooleanState(false)
  return (
    <>
      <IconButton
        source={icons.infoCircle}
        label={t("Read more")}
        color="secondary"
        onPress={openModal}
        minSize="buttonHeight"
      />
      <TextToggleModal
        title={album.title}
        open={modalOpen}
        onPressCloseButton={closeModal}
        onPressOutside={closeModal}
      >
        <Text textStyle="body">{album.description}</Text>
      </TextToggleModal>
    </>
  )
}
